import React from 'react';
import '../custom-styles.css';

const Hero = () => {
  return (
    <div className='relative'>
      {/* Default mobile hero image */}
      <div className='flex items-start h-auto w-auto md:hidden'>
        {/* Background */}
        <img src='mobile-hero.svg' alt='hero' className='w-full h-auto max-w-full'/>
        {/* All info about me */}
        <div className='absolute top-0 left-0 right-0 bottom-0 pl-10 flex flex-col pt-32 sm:pt-44 md:pt-36'>
          <h1 className='text-p-white font-serif text-2xl font-bold stroke-text'>
            <span style={{ fontFamily: 'sloop-script-one', fontWeight: 'bold', fontSize: '38px' }}>F </span>AHMIDA
          </h1>
          <h1 className='text-p-white font-serif text-2xl font-bold stroke-text'>RAHMAN</h1>
          <p className='text-sm text-[#AB645A] italic font-medium'>
            is a <span style={{ color: '#841217', textDecoration: 'underline'}}>product designer</span>
          </p>
          <div className='text-lg text-[#AB645A] italic font-medium lg:text-2xl'>
            <p className='inline lg:text-2xl xl:mt-5'>also,&nbsp;
            <span id="s1" className="spin inline"> a jack of all trades</span>
            <span id="s2" className="spin inline"> an experimentalist</span>
            <span id="s3" className="spin inline"> an explorer</span>
            <span id="s4" className="spin inline"> a cat lover</span>
            </p>
          </div>
          <p className='text-sm text-[#AB645A] italic font-medium '>who loves elevating the everyday</p>
          <div className='whitespace-normal overflow-wrap w-1/2 text-[#333333] text-xs mt-5'>
            currently studying UX Design & Entrepreneurship @ the University of Michigan
          </div>
        </div>
      </div>

      {/* Larger screens hero video */}
      <div className='hidden md:block md:w-full md:h-full md:object-cover relative'>
        <video autoPlay muted loop className='hidden md:block w-full h-auto max-w-full'>
          <source src='landing-design.mp4' type='video/mp4' />
          Your browser does not support the video tag.
        </video>
        <div className='absolute top-0 left-10 lg:left-20 lg:top-14 xl:left-24 xl:top-24 flex flex-col pt-32 xl:pt-48'>
          <h1 className='text-p-white font-serif md:text-4xl lg:text-5xl font-bold stroke xl:text-7xl' style={{ lineHeight: '0.75' }}>
            <span style={{ fontFamily: 'sloop-script-one', fontWeight: 'bold', fontSize: '120px' }}>F </span>AHMIDA
          </h1>
          <h1 className='text-p-white font-serif md:text-4xl lg:text-5xl font-bold stroke xl:text-7xl' style={{ lineHeight: '0.75' }}>RAHMAN</h1>
          <p className='mt-3 text-lg text-[#AB645A] italic font-medium lg:text-2xl xl:mt-5'>
            is a <span style={{ color: '#841217', textDecoration: 'underline'}}>product designer</span>
          </p>
          <div className='text-lg text-[#AB645A] italic font-medium lg:text-2xl'>
            <p className='inline lg:text-2xl xl:mt-5'>also,&nbsp;
            <span id="s1" className="spin inline"> a jack of all trades</span>
            <span id="s2" className="spin inline"> an experimentalist</span>
            <span id="s3" className="spin inline"> an explorer</span>
            <span id="s4" className="spin inline"> a cat lover</span>
            </p>
          </div>
          <p className='text-lg text-[#AB645A] italic font-medium lg:text-xl'>who loves elevating the everyday</p>
          <div className='whitespace-normal overflow-wrap md:w-3/4 w-1/3 text-[#333333] text-sm mt-5'>
            currently studying UX Design & Entrepreneurship @ the University of Michigan
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;


