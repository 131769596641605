import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../custom-styles.css';

const Navbar = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const location = useLocation();
    const navbarRef = useRef(null);
    const closeIconRef = useRef(null);

    const handleNav = () => {
        setIsNavOpen(prev => !prev);
    };

    const handleClickOutside = (event) => {
        if (navbarRef.current && !navbarRef.current.contains(event.target) &&
            closeIconRef.current && !closeIconRef.current.contains(event.target)) {
            setIsNavOpen(false);
        }
    };

    useEffect(() => {
        setIsNavOpen(false);
    }, [location]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <nav className='fixed top-0 left-0 right-0 bg-opacity-75 backdrop-filter backdrop-blur-lg py-4 px-6 z-50'>
            <div className='flex justify-between items-center max-w-[1240px] mx-auto px-4'>
                <Link to='/'>
                    <img src='fahmida-rahman-logo.svg' alt='my-logo' className='w-8'/>
                </Link>
                <ul className='hidden md:flex'>
                    <li className='p-4 uppercase font-sans font-semibold text-primary-red hover:text-hover-red hover:font-bold'>
                        <Link to='/'>Work</Link>
                    </li>
                    <li className='p-4 uppercase font-sans font-semibold text-primary-red hover:text-hover-red hover:font-bold'>
                        <Link to="/about">About</Link>
                    </li>
                    <li className='p-4 uppercase font-sans font-semibold text-primary-red hover:text-hover-red hover:font-bold'>
                        <Link to='/resume'>Resume</Link>
                    </li>
                </ul>
                <div onClick={handleNav} className='block md:hidden'>
                    {isNavOpen ? (
                        <img
                            src='p-menu-open.svg'
                            alt='close-menu'
                            ref={closeIconRef}
                            className='cursor-pointer'
                        />
                    ) : (
                        <img
                            src='p-menu-closed.svg'
                            alt='open-menu'
                            className='cursor-pointer'
                        />
                    )}
                </div>
                <ul
                    ref={navbarRef}
                    className={`fixed top-0 py-8 px-8 w-[60%] h-screen border-r border-r-gray-900 bg-[#ECE9DD] navbar-transition flex flex-col ${isNavOpen ? 'navbar-visible' : 'navbar-hidden'}`}
                >
                    <Link to='/'>
                        <img src='fahmida-rahman-logo.svg' alt='my-logo' className='w-8'/>
                    </Link>
                    <div className='flex flex-col flex-grow'>
                        <li className='p-4 pt-20 uppercase text-lg font-sans font-semibold text-primary-red'>
                            <Link to="/">Work</Link>
                        </li>
                        <li className='p-4 uppercase text-lg font-sans font-semibold text-primary-red'>
                            <Link to="/about">About</Link>
                        </li>
                        <li className='p-4 uppercase text-lg font-sans font-semibold text-primary-red'>
                            <Link to='/resume'>Resume</Link>
                        </li>
                    </div>
                    <div className='flex flex-col items-start space-y-4 mb-24 pl-4'>
                        <p className='text-lg font-bold text-primary-red'>Let’s Connect!</p>
                        <div className='flex space-x-4'>
                            <a href='https://www.linkedin.com/in/midarahman' target='_blank' rel='noopener noreferrer'>
                                <img src='/about-content/linkedin.svg' alt='linkedin icon' className='w-8'/>
                            </a>
                            <a href='mailto:fahmida@umich.edu'>
                                <img src='/about-content/email.svg' alt='email icon' className='w-8'/>
                            </a>
                            <a href='https://github.com/fahmidss'>
                                <img src='/github-icon.svg' alt='github icon' className='w-8'/>
                            </a>
                        </div>
                    </div>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;

