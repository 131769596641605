import React from 'react';
import Carousel from '../components/Carousel';

const images = [
    '/projects/persona-1-ss.png',
    '/projects/persona-2-ss.png'
];

const images2 = [
    '/projects/swiftshop-sketch-1.png',
    '/projects/swiftshop-sketch-2.png',
    '/projects/swiftshop-sketch-3.png',
    '/projects/swiftshop-sketch-4.png',
    '/projects/swiftshop-sketch-5.png',
]

const Swiftshop = () => {
  return (
    // header
    <div className='relative'>
        <div className='relative'>
            {/* background mobile image default */}
            <div className='md:hidden'>
                <img src='/projects/mobile-background-ss.png' alt='header background' className='w-full h-auto max-w-full'/>
                <hr className="border-t border-gray-400" />
            </div>
            
            {/* larger screens background image */}
            <div className='hidden md:block absolute inset-0 md:z-0'>
                <img src='/projects/background-lg-screens-ss.png' alt='header background' className='w-full h-auto max-w-full'/>
                <hr className="border-t border-gray-400" />
            </div>
            

            {/* overlay content */}
            <div className='absolute inset-0 md:relative md:z-10 flex flex-col items-center my-16 md:flex-row md:items-start justify-center md:pt-20 md:mr-12 p-4 md:p-8 lg:pt-48'>
                {/* mobile mockup */}
                <img src='/projects/mockup-swiftshop.png' alt='mockup of swiftshop' className='w-full md:w-1/2'/>
                {/* title + desc + grid */}
                <div className='text-left ml-6 md:ml-8 xl:pt-24'>
                    <div className='text-left ml-0'>
                        <h1 className='bg-gradient-to-r from-slate-700 to-slate-400 bg-clip-text text-transparent text-2xl mt-3'>Online Shopping Experiences Catered to a User</h1>
                        <p className='text-emphasis-gray mt-2'>Making online shopping easier with a swipe left-to-right mechanism tailored to a user's preferences.</p>
                    </div>
                    <hr className="border-t border-gray-400 my-6" />
                    <div className='grid grid-cols-2 gap-6'>
                        <div>
                            <h1 className='uppercase text-ss-dark-blue font-bold text-x mb-1 italic'>role</h1>
                            <ul className='text-ss-dark-blue'>
                                <li>UX Researcher</li>
                                <li>Product Designer</li>
                                <li>Anything in between!</li>
                            </ul>
                        </div>
                        <div>
                            <h1 className='uppercase text-ss-dark-blue font-bold text-x mb-1 italic'>duration</h1>
                            <ul className='text-ss-dark-blue'>
                                <li>Jul 2023 - Present</li>
                            </ul>
                        </div>
                        <div>
                            <h1 className='uppercase text-ss-dark-blue font-bold text-x mb-1 italic'>team</h1>
                            <ul className='text-ss-dark-blue'>
                                <li>Me!</li>
                            </ul>
                        </div>
                        <div>
                            <h1 className='uppercase text-ss-dark-blue font-bold text-x mb-1 italic'>tools</h1>
                            <ul className='text-ss-dark-blue'>
                                <li>Figma (+ FigJam)</li>
                                <li>Adobe Illustrator</li>
                                <li>Canva</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    

        {/* project overview */}
        <div className='flex flex-col mx-6 md:flex-row md:mt-60 md:mx-10 md:space-x-16 lg:mt-64 lg:mx-40 xl:mx-64 xl:mt-80'>
            <h1 className='text-ss-dark-blue font-semibold text-2xl italic pt-8'>Project Overview</h1>
            <div className='md:px-4'>
                <p className='text-p-black py-2 '>Online shopping is often overwhelming due to the sheer volume of options and the difficulty in finding prodicts that align with individual preferences. Not only do users struggle to navigate numerous platforms to find products that suit their tastes but it is time consuming and leads to a frustrating shopping experience.
                </p>
                <p className='text-p-black py-2'>To address this issue, I've proposed a new mobile application called "SwiftShop" which is designed to <b>streamline the online shopping journey</b> by implementing a swipe left-to-right mechanism that is reminiscent of Tinder. This app provides a one-stop shop that tailors recommendations to a user's needs, making shopping more personal, efficient, and engaging.</p>
                <p className='text-p-black py-2 '>As the sole researcher and designer of this project, I've conducted UXR to refine the app's user experience and making intentional design decisions that went into the final prototype.</p>
            </div>
            
        </div>

        {/* quick look */}
        
        <div className='mx-6 md:mx-10 lg:mx-40 xl:mx-64'>
            <h1 className='text-2xl text-ss-dark-blue font-bold py-6 italic'>A Quick Look</h1>
            <img src='/projects/quick-look-ss.gif' alt='motion gif of swiftshop' className='w-full'/>
        </div>
        <hr className="border-t border-gray-400" />
        
        {/* the beginning */}
        <div className='mx-6 md:mx-10 lg:mx-40 xl:mx-64'>
            <h1 className='text-2xl text-ss-dark-blue font-bold pt-10 italic'>Mistake #1..</h1>
            <p className='text-p-black py-2 md:pr-8'>Heading into this project, my enthusiasm overtook me and I did not thoroughly spend time to identify and frame the problem. The discovery phase is so important to UXR and formulating a good problem and 'How might we' (HMW) statement is the key to providing direction. We also need to think about solving the <b>right problem</b> in order to give the right solution.</p>
            <p className='text-p-black py-2 md:pr-8'>This prompted me to take a step back and clearly map out what needed to be addressed and how to address it. With the help of Dwayne Spradlin's article on "Are You Solving the Right Problem" (<a href="https://hbr.org/2012/09/are-you-solving-the-right-problem" target="_blank" rel="noopener noreferrer">https://hbr.org</a>), I circled back to the discovery phase.</p>
        </div>

        {/* PHASE 1 HEADER*/}
        <div className='bg-cream my-6'>
            <hr className="border-t border-gray-400" />
            <div className='grid grid-cols-2 gap-10 mx-6 my-4 md:mx-10 lg:mx-40 xl:mx-64'>
                <div className='md:text-xl'>01</div>
                <div className='uppercase md:text-xl md:text-right'>discovery phase</div>
            </div>
            <hr className="border-t border-gray-400" />
        </div>

        <div className='flex flex-col items-center mx-6 py-4 md:mx-10 lg:mx-40 xl:mx-64'>
            <img src='/projects/question-mark.png' alt='question mark' className='lg:w-20'/>
            <div className='text-center py-2 md:text-xl lg:text-2xl'>So, HMW <span style={{ color: '#405a66', fontWeight:'600'}}>simplify</span> the online shopping experience while incorporating <span style={{ color: '#405a66', fontWeight:'600'}}>engagement</span> and <span style={{ color: '#405a66', fontWeight:'600'}}>efficiency</span>?</div>
            
        </div>

        <hr className="border-t border-gray-400" />

        {/* initial research */}
        <div className='mx-6 py-4 md:mx-10 lg:mx-40 xl:mx-64'>
            <h2 className='text-sm text-emphasis-gray pt-4 md:text-lg'>Familiarize ourselves with the problem</h2>
            <h1 className='font-semibold pb-6 md:text-xl'>Initial Research</h1>
            <img src='/projects/reddit-quotes.png' alt='reddit quotes' className='w-full'/>
            <p className='text-xs text-gray-500 text-center italic md:text-base md:pb-8'>Figure 1: Image depicting Reddit user's opinions and experiences with online shopping.</p>
            <p className='text-p-black py-2 md:pr-8'>In order to gauge user's preferences, behaviors, and motivations—I needed to research the broad subject of online shopping experiences. The quotes above are some notable opinions that were popular amongst Redditors and came from different subreddits.</p>
            <p className='text-p-black py-2 md:pr-8'>When researching, I've learned that not all findings have to contain user painpoints in a certain experience, it is valuable to <span style={{ textDecoration:'underline' }}>also</span> know what a user <span style={{ color: '#405a66', fontWeight:'600'}}>appreciates</span>, the potential <span style={{ color: '#405a66', fontWeight:'600'}}>opportunities</span>, and <span style={{ color: '#405a66', fontWeight:'600'}}>comparisons</span> being made.</p>

            <h2 className='text-base text-emphasis-gray pt-4 font-medium md:text-lg'>So who are we competing with?</h2>
            <img src="/projects/competitive-landscape.png" alt='competitive landscape' className='pt-6'/>
            <p className='text-xs text-gray-500 text-center italic md:text-base md:pb-8'>Figure 2: Image of a competitive landscape with companies/platforms that SwiftShop directly and indirectly competes against.</p>
            <p className='text-p-black pt-6 font-medium md:text-lg'>Now what do we do?</p>
            <ul className='py-2'>
                <li>✅  Ensure that recommendation are personal and relevant to the user based on browsing</li>
                <li>✅  Include a wide variety of selections that users can narrow down easily</li>
                <li>❌  Exclude features that users love 💙</li>
            </ul>
        </div>
        
        <hr className="border-t border-gray-400" />

        {/* second stage of research */}
        <div className='mx-6 py-4 md:mx-10 lg:mx-40 xl:mx-64'>
            <h2 className='text-sm text-emphasis-gray pt-4 md:text-lg'>Digging deeper with real people</h2>
            <h1 className='font-semibold pb-4 md:text-xl'>Further Research</h1>
            {/* first method */}
            <h2 className='uppercase text-ss-dark-blue text-sm font-medium md:text-base'> ▪ surveys</h2>
            <p className='text-p-black pl-2 md:text-base md:pr-8'>One research method used was a simple survey that was distributed among different threads on Reddit not entirely specific to online shopper to gather a diverse demongraphic of respondents. The survey received 35+ responses. The participants also remained anonymous with no personal information shared to ensure more comfortability in answering the questions.</p>
            <p className='text-p-black pt-6 pl-2 md:text-base'>What did the survey results reveal?</p>
            <img src='/projects/survey-results-ss.png' alt='survey results'/>
            <p className='text-xs text-gray-500 text-center italic md:text-base md:pb-8'>Figure 3: Data from the survey regarding people's online shopping experiences.</p>

            {/* second method */}
            <h2 className='uppercase text-ss-dark-blue text-sm font-medium pt-8 md:text-base'> ▪ interviews</h2>
            <p className='text-p-black pl-2 md:pr-8'>Following the surveys, I needed in-depth explanations and stories that would allow better understanding of user experiences and behaviors. Hence, I conducted 3 interviews lasting 30-45 minutes from willing participants.</p>
            {/* QUOTES FROM INTERVIEWS */}
            <p className='text-p-black pl-2 pt-2'>Here are some direct quotes that stood out from each participant:</p>
            <div className='flex flex-col items-center my-4 rounded-lg shadow-md bg-white-cream md:flex-row'>
                <img src='/projects/user-icon-ss.png' alt='icon of a user' className='w-14 object-cover mt-4 md:w-20 md:mx-4'/>
                <p className='text-p-black p-4 md:p-10'>"[I] browse the shopping landscape, on, you know, Google, just to see what's around, sometimes it's a matter of like getting the right keyword to so like, maybe what I'm thinking of it as is something a little different. And then once I start searching for it, I noticed that, you know, all of the products are titled, something slightly different than when I'm typing in..." - participant 1</p>
            </div>
            <div className='flex flex-col items-center my-4 rounded-lg shadow-md bg-white-cream md:flex-row'>
                <img src='/projects/user-icon-ss.png' alt='icon of a user' className='w-14 object-cover mt-4 md:w-20 md:mx-4'/>
                <p className='text-p-black p-4 md:p-10'>“It really annoys me when I have to re-login to a place every time I use it [...] Anything that’s one less step is good.” - participant 2</p>
            </div>
            <div className='flex flex-col items-center my-4 rounded-lg shadow-md bg-white-cream md:flex-row'>
                <img src='/projects/user-icon-ss.png' alt='icon of a user' className='w-14 object-cover mt-4 md:w-20 md:mx-4'/>
                <p className='text-p-black p-4 md:p-10'>“I honestly find new products through social media, especially TikTok and dupes on the internet from Pinterest outfit inspos. I also check out the “recommended for you” sections on websites when I’m shopping because it’s hard to like look at things that are not trendy looking.” - participant 3</p>
            </div>
        </div>

        {/* PHASE 2 HEADER */}
        <div className='bg-cream mt-6'>
            <hr className="border-t border-gray-400"/>
            <div className='grid grid-cols-2 gap-10 mx-6 my-4 md:mx-10 md:text-xl lg:mx-40 xl:mx-64'>
                <div>02</div>
                <div className='uppercase text-right'>empathize and synthesize phase</div>
            </div>
            <hr className="border-t border-gray-400"/>
        </div>

        <div className='mx-6 py-4 md:mx-10 lg:mx-40 xl:mx-64'>
            <h2 className='text-sm text-emphasis-gray pt-4 md:text-lg'>Understanding the User</h2>
            <h1 className='font-semibold pb-4 md:text-xl'>Cluster Analysis</h1>
            <p className='text-p-black md:pr-8'>After gathering both quantitative and qualitative date from the surveys and interviews, I was able to extract three central themes with clustering analysis. These themes appeared often in the responses given by participants of the research and will help inform design decisions.</p>
            <img src='/projects/cluster-ss.png' alt='cluster themes' className='shadow-md my-4'/>
            <p className='text-xs text-gray-500 text-center italic md:text-base md:pb-8'>Figure 4: Clustered overarching themes from analyzing the users.</p>
        </div>

        {/* personas */}
        <div className='mx-6 pb-4 md:mx-10 lg:mx-40 xl:mx-64'>
            <h2 className='text-sm text-emphasis-gray pt-4 md:text-lg'>Getting precise</h2>
            <h1 className='font-semibold pb-4 md:text-xl'>Creating personas</h1>   
            <p className='text-p-black md:pr-8'>From the common themes of the research, I created two personas that represent two types of users that would benefit from an ehanced online shopping experience. Click the carousel buttons below to switch between personas:</p>
            <div className='mx-6 my-4 md:mx-10'>
                <Carousel images={images} />
            </div>
            <p className='text-xs text-gray-500 text-center italic md:text-base md:pb-8'>Figure 5: Persona 1 & 2 created from analyzing the data and forming two distinct characters.</p>
        </div>

        {/* competitive audit and design pattern adoption */}
        <div className='mx-6 pb-2 md:mx-10 lg:mx-40 xl:mx-64'>
            <h2 className='text-sm text-emphasis-gray pt-4 md:text-lg'>Leveraging Familiarity for User Intuition</h2>
            <h1 className='font-semibold pb-4 md:text-xl'>Competitive Audit</h1>
            <p className='text-p-black md:pr-8'>Due to the nature of this app, there are a ton of features that users are able to utilize. Hence, in order to ease usability and reduce learning curves, I wanted to implement familair systems and recognizable UI patterns whilst maintaining originality.</p>
            {/* mobile ss */}
            <div className='block lg:hidden'>
                <img src='/projects/mobile-audit-ss.png' alt='design pattern adoption' className='items-center'/>
            </div>
            {/* larger screens ss */}
            <div className='hidden lg:block'>
                <img src='/projects/big-audit-ss.png' alt='design pattern adoption' className='items-center'/>
            </div>
            
            <p className='text-xs text-gray-500 text-center italic md:text-base md:pb-8'>Figure 6: Overlook of different design patterns that inspired the UI of Swiftshop for user familiarity.</p>
        </div>

        {/* core product design principles */}
        <div className='bg-gradient-to-r from-cream to-ss-light-blue'>
            <hr className="border-t border-gray-400" />
            <div className='flex flex-col mx-6 my-4 md:flex-row md:mx-10 md:my-10 lg:mx-40 xl:mx-64'>
                <div className='px-6 pb-4'>
                    <h2 className='font-semibold md:text-lg'>💃 keep 'em coming</h2>
                    <p className='md:pt-4'>Make sure the user immerses themselves in shopping but don't overload them with choices so that it's overwhelming.</p>
                </div>
                <div className='px-6 pb-4'>
                    <h2 className='font-semibold md:text-lg'>😎 easy for everyone</h2>
                    <p className='md:pt-4'>Incorporate intuitive design through multiple outlets such as cohesive interface, familiarity, and comprehensive icons.</p>
                </div>
                <div className='px-6 pb-4'>
                    <h2 className='font-semibold md:text-lg'>🧠 remember me?</h2>
                    <p className='md:pt-4'>Ensure that everything is seamless—down to the smallest details. Users have lots to remember, make it easier to browse and shop.</p>
                </div>
            </div>
            <hr className="border-t border-gray-400" />
        </div>

        {/* PHASE 3 HEADER */}
        <div className='bg-cream'>
            <hr className="border-t border-gray-400"/>
            <div className='grid grid-cols-2 gap-10 mx-6 my-4 md:mx-10 md:text-xl lg:mx-40 xl:mx-64'>
                <div>03</div>
                <div className='uppercase text-right'>ideation phase</div>
            </div>
            <hr className="border-t border-gray-400"/>
        </div>

        <div className='mx-6 py-4 md:mx-10 lg:mx-40 xl:mx-64'>
            <h2 className='text-sm text-emphasis-gray pt-4 md:text-lg'>Decisions, decisions, decisions</h2>
            <h1 className='font-semibold pb-4 md:text-xl'>Hypothesizing the Design</h1>
            <p className='text-p-black md:pr-8'>In order to develop the overall structure of the app, I needed a guide as to what pages I was going to be working with. Thus, I created a sitemap to provide a good blueprint.</p>
            <img src='/projects/sitemap-ss.png' alt='sitemap of swiftshop'/>
            <p className='text-xs text-gray-500 text-center italic'>Figure 7: Sitemap of the overall structure the platform was to be built around.</p>
            
            <p className='text-p-black md:pr-8 md:mt-6'>To account for each step of the different processes on the app, a user flow map was also created. This not only defined how users will interact with the app but assisted with guiding the creation of final wireframes.</p>
            <img src='/projects/userflow-ss.png' alt='userflow of swiftshop'/>
            <p className='text-xs text-gray-500 text-center italic md:text-base md:pb-8'>Figure 8: User flow map of a users journey with the app.</p>

            <p className='text-p-black mt-4 md:pr-8'>Thinking about wireframing for a whole project was a bit overwhelming but with inspiration from other platforms, the process was manageable. Thinking about all the different screens and what each button leads to is impossible to do from the get-go. That's why I started out with simple sketches based on the flows created earlier before I could work the real magic on Figma.</p>
            <p className='text-p-black mt-2'>Click through the slides below to see the different pages:</p>
            <div className='mx-6 my-4 md:mx-10'>
                <Carousel images={images2} />
            </div>
            <p className='text-xs text-gray-500 text-center italic md:text-base md:pb-8'>Figure 9: Initial sketches for the prototype.</p>
        </div>

        {/* PHASE 4 HEADER */}
        <div className='bg-cream'>
            <hr className="border-t border-gray-400" />
            <div className='grid grid-cols-2 gap-10 mx-6 my-4 md:mx-10 md:text-xl lg:mx-40 xl:mx-64'>
                <div>04</div>
                <div className='uppercase text-right'>execution phase</div>
            </div>
            <hr className="border-t border-gray-400"/>
        </div>

        <div className='mx-6 pt-4 md:mx-10 lg:mx-40 xl:mx-64'>
            <h2 className='text-sm text-emphasis-gray pt-4 md:text-lg'>Wrapping it up</h2>
            <h1 className='font-semibold pb-4 md:text-xl'>Final Designs</h1>
        </div>
        <div>
            <hr className="border-t border-gray-400"/>
            <video autoPlay muted loop className='w-full h-auto max-w-full '>
                <source src='projects/swiftshop-product.mp4' type='video/mp4' />
                Your browser does not support the video tag.
            </video>
            <hr className="border-t border-gray-400"/>
        </div>

        <h1 className='font-semibold py-4 mx-6 text-lg md:mx-10 md:mt-10 lg:mx-40 xl:mx-64'>Prototype Visualization</h1>
        {/* ACCOUNT LOGIN */}
        <div className='mb-8 mx-6 flex flex-col md:flex-row md:mx-10 lg:mx-40 xl:mx-64'>
            <div className='px-10'>
                <h2 className='uppercase text-ss-dark-blue text-base font-medium'> ▪ LOGIN</h2>
                <ul className='list-disc mx-6 md:mx-10 lg:pr-28'>
                    <li>Streamlined User Onboarding: allows for saved account information that shortens steps and reduces friction by offering Google login</li>
                    <li>Enhanced User Engagement: provides success message for emotional impact and matches scale of the action.</li>
                </ul>
            </div>
            <div className=''>
                <img src='/projects/login.gif' alt='gif of account login' className='rounded-3xl border-4 my-2 md:rounded-xl xl:rounded-2xl md:max-w-72 md:h-auto'/>
                <p className='text-xs text-gray-500 text-center italic md:text-base md:pb-8'>Figure 10: Prototype of account login.</p>
            </div>
        </div>

        {/* ACCOUNT SIGN UP */}
        <div className='mb-8 mx-6 flex flex-col md:flex-row md:mx-10 lg:mx-40 xl:mx-64'>
            <div>
                <h2 className='uppercase text-ss-dark-blue text-base font-medium'> ▪ SIGNUP</h2>
                <ul className='list-disc mx-6 md:mx-10 lg:pr-28'>
                    <li>Personalization: users are able to customize to their preferences for better alignment of their needs.</li>
                    <li>Guiding the User: progress indicators facilitates user registration and shows visibility of system status.</li>
                    <li>User Control and Freedom: easy for users to undo actions like go back to a previous page or change preferenes.</li>
                </ul>
            </div>
            <div className=''>
                <img src='/projects/sign-up.gif' alt='gif of account sign up' className='rounded-3xl border-4 my-2 md:rounded-xl xl:rounded-2xl md:max-w-72 md:h-auto'/>
                <p className='text-xs text-gray-500 text-center italic md:text-base md:pb-8'>Figure 11: Prototype of account signup and selection.</p>
            </div>
        </div>

        {/* MATCHMAKING */}
        <div className='mb-8 mx-6 flex flex-col md:flex-row md:mx-10 lg:mx-40 xl:mx-64'>
            <div>
                <h2 className='uppercase text-ss-dark-blue text-base font-medium'> ▪ MATCHMAKING</h2>
                <ul className='list-disc mx-6 md:mx-10 lg:pr-28'>
                    <li>Personalization & Engagement: gamified swiping that also provides feedback for tailored recommendations.</li>
                    <li>Familiarity principle & Design consistency: similar features to Tinder andtypical product details page for easier navigation to users.</li>
                    <li>Hick's Law & No Information Overload: one product at a time minimizes choices presented, reduces information overload, and increases decision-making.</li>
                </ul>
            </div>
            <div className=''>
                <img src='/projects/swipe.gif' alt='gif of account sign up' className='rounded-3xl border-4 my-2 md:rounded-xl xl:rounded-2xl md:max-w-72 md:h-auto'/>
                <p className='text-xs text-gray-500 text-center italic md:text-base md:pb-8'>Figure 12: Prototype of swiping left-to-right feature for matchmaking.</p>
            </div>
        </div>

        {/* DISCOVERY */}
        <div className='mb-8 mx-6 flex flex-col md:flex-row md:mx-10 lg:mx-40 xl:mx-64'>
            <div>
                <h2 className='uppercase text-ss-dark-blue text-base font-medium'> ▪ DISCOVER</h2>
                <ul className='list-disc mx-6 md:mx-10 lg:pr-28'>
                    <li>User Exploration: encourages product exploration and interaction for more user engagement.</li>
                    <li>Tailored Experience: offers individualized attention to the user with recommendations as well as interactions relevant to the user's shopping experience.</li>
                    <li>Streamlined Navigation: convenience of search bar and showing relevant information to users for simple navigation.</li>
                </ul>
            </div>
            <div className=''>
                <img src='/projects/discover.gif' alt='gif of discover' className='rounded-3xl border-4 my-2 md:rounded-xl xl:rounded-2xl md:max-w-72 md:h-auto'/>
                <p className='text-xs text-gray-500 text-center italic md:text-base md:pb-8'>Figure 13: Prototype of discover and store selection page.</p>
            </div>
        </div>

        {/* CART */}
        <div className='mb-8 mx-6 flex flex-col md:flex-row md:mx-10 lg:mx-40 xl:mx-64'>
            <div>
                <h2 className='uppercase text-ss-dark-blue text-base font-medium'> ▪ CART</h2>
                <ul className='list-disc mx-6 md:mx-10 lg:pr-28'>
                    <li>Reduced Cart Abandonment: saved items page allows for preservation of interest as well as reducing cognitive load on potential purchases.</li>
                    <li>User Comprehension: clear icons, grouping by sections, and success/confirmation messages creates an intuitive interface for good usability.</li>
                    <li>Efficiency Optimization: friction reduction from saved shipping and payment details creates a smooth experience for the user by reducing obstacles of inputting redundant information.</li>
                </ul>
            </div>
            <div className=''>
                <img src='/projects/cart.gif' alt='gif of cart and payment' className='rounded-3xl border-4 my-2 md:rounded-xl xl:rounded-2xl md:max-w-72 md:h-auto'/>
                <p className='text-xs text-gray-500 text-center italic md:text-base md:pb-8'>Figure 14: Prototype of cart and checkout process.</p>
            </div>
        </div>

        {/* ACCOUNT */}
        <div className='mb-8 mx-6 flex flex-col md:flex-row md:mx-10 lg:mx-40 xl:mx-64'>
            <div>
                <h2 className='uppercase text-ss-dark-blue text-base font-medium'> ▪ ACCOUNT</h2>
                <ul className='list-disc mx-6 md:mx-10 lg:pr-28'>
                    <li>Enahnced User Control: ability for the user to customize at any point enabling user control and freedom and facilitates account management.</li>
                    <li>Promotes Self-Service: reduces the need for customer support by resolving issues on their own</li>
                    <li>Information Access: users can update their own information at any time while accessing past transactions and information without needing to use their own memory.</li>
                </ul>
            </div>
            <div className=''>
                <img src='/projects/account.gif' alt='gif of account page amd settings' className='rounded-3xl border-4 my-2 md:rounded-3xl md:max-w-72 md:h-auto'/>
                <p className='text-xs text-gray-500 text-center italic md:text-base md:pb-8'>Figure 15: Prototype of account page of customizations and settings.</p>
            </div>
        </div>

        {/* FINAL REFLECTIONS */}
        <div className='bg-gradient-to-r from-cream to-ss-light-blue'>
            <hr className="border-t border-gray-400" />
            <div className='mx-6 my-4 md:mx-10 md:my-10 lg:mx-40 xl:mx-64'>
                <div className='uppercase text-ss-dark-blue font-bold text-xl italic font-serif'>final reflections</div>
                <div className='pt-2 md:pr-8'>While this project was long overdue in my personal list of things to accomplish, I'm so glad that I finished it after going through courses and other projects that taught me valuable things. If I had continued this the first time I was ideating, I would not have done a thorough job. I was taught the <span style={{ color: '#405a66', fontWeight:'600'}}>value of research</span> and the ability to <span style={{ color: '#405a66', fontWeight:'600'}}>create effective insights and opportunities</span> that inform design decisions in the right manner. While this is simply a passion project, there are still many iterations that SwiftShop needs to go through to optimize usability. As we know, UX design involves <span style={{ color: '#405a66', fontWeight:'600'}}>constant refinement</span> based on new user findings and feedback—it is an evolving process without a final endpoint.</div>
            </div>
            <hr className="border-t border-gray-400"/>
        </div>
        
        {/* THANKS */}
        <div className='text-center'>
            <img src='/projects/thanks-ss.png' alt='thank you for scrolling' className='md:w-1/2 md:mx-auto'/>
        </div>
    </div>
    

  )
}

export default Swiftshop