import React from 'react'

const Resume = () => {
  return (
    <div>
    <div className="pt-32 pb-20 px-4 md:px-8 lg:px-16">
        <div className='text-center'>
            <h1 className="text-3xl font-bold text-primary-red pb-8">My Resume</h1>
        </div>
        <div className='flex flex-col items-center justify-center mb-8'>
            <iframe
            src="Rahman_Fahmida_Resume_24.pdf"
            className="w-3/4 max-w-4xl h-[70vh] md:h-[80vh] lg:h-[90vh] border-none"
            style={{ border: 'none' }}
            title="PDF Viewer"
            />
        </div>

        <div className='text-center text-primary-red font-medium text-xl'>
            <p>Here is a brief overview of my resume.</p>
            <p>Not viewable?</p>
            {/* You can include more details or even link to a downloadable resume file */}
            <a href="https://drive.google.com/file/d/1VREQkiHcbLFWCkrUl9drUiIk7bQINUD6/view?usp=sharing" className="text-blue-500">Find it here instead.</a>
        </div>
    </div>
    </div>
  )
}

export default Resume