import React from 'react'

const LillianAugusta = () => {
  return (
    <div className='relative'>
        <div className='relative'>
            {/* background mobile image default */}
            <div className='md:hidden'>
                <img src='/lillian-content/bg-lillian-mobile.png' alt='header background' className='w-full h-auto max-w-full'/>
                <hr className="border-t border-gray-600" />
            </div>
            
            {/* larger screens background image */}
            <div className='hidden md:block absolute inset-0 md:z-0'>
                <img src='/lillian-content/bg-lillian-big.png' alt='header background' className='w-full h-screen max-w-full'/>
                <hr className="border-t border-gray-600" />
            </div>
            

            {/* overlay content */}
            <div className='absolute inset-0 md:relative md:z-10 flex flex-col items-center my-16 pt-12 md:flex-row md:items-start justify-center md:pt-20 lg:pt-44 md:mx-32'>
                {/* mobile mockup */}
                <img src='/lillian-content/lillian-cover.png' alt='lillian augusta cover' className='w-64 lg:w-80 md:w-1/2'/>
                {/* title + desc + grid */}
                <div className='text-left ml-6 md:ml-16 xl:mr-32'>
                    <div className='text-left ml-0'>
                        <h1 className='bg-gradient-to-r from-amber-950 to-rose-800 bg-clip-text text-transparent text-2xl mt-3'>Harmonizing Haircare: Unveiling Consumer Insights for Lillian Augusta's 'Hair Without Harm</h1>
                        <p className='text-gray-600 mt-2'>Exploration of customer journey research and market opportunities for a B2C process.</p>
                    </div>
                    <hr className="border-t border-gray-600 my-6" />
                    <div className='grid grid-cols-2 gap-6'>
                        <div>
                            <h1 className='uppercase text-lillian-black font-bold text-x mb-1 italic'>role</h1>
                            <ul className='text-lillian-black'>
                                <li>UX Researcher</li>
                                <li>Entrepreneur</li>
                            </ul>
                        </div>
                        <div>
                            <h1 className='uppercase text-lillian-black font-bold text-x mb-1 italic'>duration</h1>
                            <ul className='text-lillian-black'>
                                <li>Jan 2024-Apr 2024</li>
                            </ul>
                        </div>
                        <div>
                            <h1 className='uppercase text-lillian-black font-bold text-x mb-1 italic'>team</h1>
                            <ul className='text-lillian-black'>
                                <li>Fahmida Rahman</li>
                                <li>Emily You</li>
                                <li>Maddy Meyer</li>
                                <li>Ryan Bentley</li>
                                <li>Phoebe Oh</li>
                            </ul>
                        </div>
                        <div>
                            <h1 className='uppercase text-lillian-black font-bold text-x mb-1 italic'>tools</h1>
                            <ul className='text-lillian-black'>
                                <li>Figma (+ FigJam)</li>
                                <li>Adobe Illustrator</li>
                                <li>Canva</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='pt-28 text-primary-red text-center text-3xl font-bold my-36'>
            <h1>Under Construction! Stay Tuned :D</h1>
        </div>
    </div>
    
  )
}

export default LillianAugusta