import React, { useState } from 'react';

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className="relative w-full max-w-3xl mx-auto">
      {/* Image Container */}
      <div className="relative overflow-hidden">
        <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          className="w-full h-auto object-cover"
        />
      </div>
      
      {/* Navigation Buttons Container */}
      <div className="absolute inset-0 flex items-center justify-between px-4">
        <button
          onClick={prevSlide}
          className="bg-ss-dark-blue text-white w-8 h-8 rounded-full z-10"
          style={{ marginLeft: '-3.5rem' }} 
        >
          &#10094;
        </button>
        <button
          onClick={nextSlide}
          className="bg-ss-dark-blue text-white w-8 h-8 rounded-full z-10"
          style={{ marginRight: '-3.5rem' }}
        >
          &#10095;
        </button>
      </div>

      {/* Pagination Dots */}
      <div className="flex justify-center space-x-2 mt-4">
        {images.map((_, index) => (
          <span
            key={index}
            className={`block w-2 h-2 rounded-full ${index === currentIndex ? 'bg-ss-dark-blue' : 'bg-gray-300'}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;


