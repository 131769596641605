import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    // whole footer

    <div className='flex flex-col w-full text-p-black bg-cream' style={{background: 'url(overlay-footer.svg)'}}>
        <div className='flex flex-row  w-full justify-around mt-10 mb-10 md:justify-between md:px-20 lg:mt-16 lg:px-24'>
        <div className='flex flex-col px-5 justify-between md:px-2'>
            <div className='bg-gradient-to-r from-p-red-alt to-dark-red bg-clip-text text-transparent font-bold text-lg lg:text-xl'>
                Don't be shy, let's connect!
            </div>
            <div className='flex flex-col'>
                <a href='https://linkedin.com/in/midarahman' className='underline hover:text-p-red-alt duration-200'>linkedin ↗</a>
                <a href='mailto: fahmida@umich.edu' className='underline hover:text-p-red-alt duration-200'>email ↗</a>
                <a href='https://github.com/fahmidss' className='underline hover:text-p-red-alt duration-200'>github ↗</a>
            </div>
        </div>
        <div className='flex flex-col px-5 justify-between md:px-2'>
        <div className='bg-gradient-to-r from-p-red-alt to-dark-red bg-clip-text text-transparent font-bold text-lg uppercase lg:text-xl'>
                Navigate
            </div>
        <div className='flex flex-col'>
            <Link to='/' className='hover:text-p-red-alt duration-200'>Home</Link>
            <Link to='/about' className='hover:text-p-red-alt duration-200'>About</Link>
            <Link to='/resume' className='hover:text-p-red-alt duration-200'>Resume</Link>
        </div>
        </div>
        </div>
        <div className='text-center text-sm mb-10'>
            <div>
                Designed & Developed by Fahmida Rahman © 2024
            </div>
            <div>
                Built with React and Tailwind CSS
            </div>
             
        </div>
    </div>
  )
}

export default Footer